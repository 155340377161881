<template>
  <div>
    <b-row
      center
      class="d-flex"
      style="padding-top:20%"
    >
      <b-col
        md="6"
        offset-md="3"
      >
        <b-card
          v-if="showLoading"
        >
          <b-card-body
            class="text-center"
          >
            <h5>Bitte haben Sie etwas Geduld, während die Daten geladen werden.</h5>
            <br>
            <br>
            <b-spinner
              variant="primary"
            />
          </b-card-body>
        </b-card>
        <b-card
          v-if="!showLoading"
        >
          <b-card-body
            class="text-center"
          >
            <h5>Partner nicht gefunden, bitte überprüfen Sie Ihren Link!</h5>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BSpinner,
  BCard,
  BCardBody,
  BRow,
  BCol,
} from 'bootstrap-vue'

import {
  ref, onUnmounted,
} from '@vue/composition-api'

import router from '@/router'

import store from '@/store'
import homeStoreModule from '../homeStoreModule'

export default {
  components: {
    BSpinner,
    BCard,
    BCardBody,
    BRow,
    BCol,
  },
  setup() {
    window.$cookies.remove('userData')
    window.$cookies.remove('partnerData')
    window.$cookies.remove('accessToken')
    window.$cookies.remove('refreshToken')

    let partner = router.currentRoute.params.partner.toLowerCase()
    // . the beautifull exceeptions
    if (router.currentRoute.params.partner.toLowerCase() === 'kitzbühel') {
      partner = 'kitzbuehel'
    }

    const showLoading = ref(true)

    const appIdElem = document.getElementById('app')
    let backgroundImage = ''
    setTimeout(() => {
      if (typeof (window.$cookies.get('partner')) !== 'undefined') {
        backgroundImage = require(`@/assets/images/partner/${partner}/backgorund/auth.jpg`)
        appIdElem.style.backgroundImage = `url('${backgroundImage}')`

        if ((window.$cookies.get('partner') === 'raiffeisen-leasing_genol') || (window.$cookies.get('partner') === 'raiffeisen_genol') || (window.$cookies.get('partner') === 'emobil-link')) {
          appIdElem.style.backgroundPosition = 'top right'
        }
      }
    }, 200)

    //  app.ladestellenbonus.at/Partner/Kitzbuehel
    //  http://localhost:8086/partner/Kitzbuehel
    //  http://localhost:8086/Partner/Kitzbuehel

    //  window.$cookies.set('partner', partner, Infinity)
    window.$cookies.set('partner', partner, Infinity, null, null, true, 'None')

    const APP_HOME_STORE_MODULE_NAME = 'app-home'

    // Register module
    if (!store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.registerModule(APP_HOME_STORE_MODULE_NAME, homeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.unregisterModule(APP_HOME_STORE_MODULE_NAME)
    })

    // . fallback to register
    let redirect = '/register'

    if (router.currentRoute.params.redirect != null) {
      redirect = `/${router.currentRoute.params.redirect}`
    }

    if (typeof (router.currentRoute.params.redirect) !== 'undefined') {
      redirect = `/${router.currentRoute.params.redirect}`

      if (typeof (router.currentRoute.params.token) !== 'undefined') {
        redirect = `${redirect}/${router.currentRoute.params.token}`
      }
    }

    setTimeout(() => {
      showLoading.value = true
      store
        .dispatch(`${APP_HOME_STORE_MODULE_NAME}/getPartnerData`, { partnerKey: partner })
        .then(response => {
          if (response.status === 200) {
            //  console.log(response.data)
            //  window.$cookies.set('partnerData', response.data, Infinity)
            window.$cookies.set('partnerData', response.data, Infinity, null, null, true, 'None')

            setTimeout(() => {
              //  console.log(response.data)
              document.title = response.data.htmlPageTitle
              document.querySelector("link[rel*='icon']").href = `favicon_${response.data.key}.ico`
              router.push(redirect)
            }, 500)
          } else {
            // . fallback to defaults
            showLoading.value = false
            store
              .dispatch(`${APP_HOME_STORE_MODULE_NAME}/getPartnerData`, { partnerKey: 'ladestellenbonus' })
              .then(responseFallback => {
                if (responseFallback.status === 200) {
                  //  window.$cookies.set('partnerData', responseFallback.data, Infinity)
                  window.$cookies.set('partnerData', responseFallback.data, Infinity, null, null, true, 'None')

                  document.title = responseFallback.data.htmlPageTitle
                  document.querySelector("link[rel*='icon']").href = `favicon_${responseFallback.data.key}.ico`
                  //  router.push(redirect)
                }
              })
          }
        })
        .catch(() => {
          showLoading.value = false
        })
    }, 1500)

    return {
      showLoading,
    }
  },
}
</script>
